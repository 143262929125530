import React from "react";
import OrderRow from "./OrderRow";

const OrdersData = (props) => {
  const { orders } = props;
  const { searchData } = props;

  if (!orders || orders.length === 0) return <p>Нет данных.</p>;
  return (
    <table border="1">
      <tbody align="right">
        <tr>
          <th width="25%">Адрес</th>
          <th width="15%">Контакт</th>
          <th width="20%">Заказ</th>
          <th width="30%">Изделие</th>
        </tr>
        {Object.keys(orders).map((indexYear, value) => {
          return Object.keys(orders[indexYear]).map((index, value) => {
            return (
              <OrderRow
                orderData={orders[indexYear][index]}
                searchData={searchData}
                key={indexYear + index}
              />
            );
          });
        })}
      </tbody>
    </table>
  );
};

export default OrdersData;
