import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import Search from "./Search";
import OrdersData from "./OrdersData";

const OrdersTable = () => {
  const [state, setState] = useState({ searchData: "" });
  const [orders, setOrders] = useState({});

  useEffect(() => {
    if (state.searchData.length > 2) {
      axios
        .post("https://fyberi-steklandia.na4u.ru/api.php", {
          search: state.searchData,
        })
        .then((data) => {
          const allOrders = data.data;
          setOrders(allOrders);
        });
    }
  }, [state.searchData]);

  const SearchOrders = _.debounce((data) => {
    setState({ searchData: data });
  }, 500);

  return (
    <div>
      <Search UpdateSearch={SearchOrders} />
      {state.searchData.length > 2 && (
        <OrdersData orders={orders} searchData={state.searchData} />
      )}
    </div>
  );
};

export default OrdersTable;
