import React, { useState, useEffect, useMemo } from "react";
import { addUser } from "../../api/users";

const validate = (userForm, isAdd) => {
  const errors = {};
  if (!userForm.login.value) {
    errors.login = "Заполните логин";
  }
  if (userForm.password.value.length < 3) {
    errors.password = "Заполните пароль";
  }
  return errors;
};

const AddUserForm = ({ updatesViewUsers, handleAddUser }) => {
  const [userForm, setUserForm] = useState({
    login: { value: "", isTouched: false },
    password: { value: "", isTouched: false },
  });
  const [isAdd, setIsAdd] = useState(false);

  const errors = validate(userForm, isAdd);

  const submitHandler = (event) => {
    event.preventDefault();
    if (Object.keys(errors).length === 0) {
      let userValue = Object.keys(userForm).map((key) => [
        key,
        userForm[key].value,
      ]);
      addUser(userValue, handleAddUser);
      setIsAdd(true);
    } else {
      setUserForm({
        ...userForm,
        login: { ...userForm.login, isTouched: true },
        password: { ...userForm.password, isTouched: true },
      });
    }
  };

  const changeIsAdd = (data) => {
    setIsAdd(data);
  };

  useMemo(() => {
    if (isAdd === true) {
      setUserForm({
        ...userForm,
        login: { ...userForm.login, value: "", isTouched: false },
        password: { ...userForm.password, value: "", isTouched: false },
      });
      setIsAdd(false);
    }
  }, [isAdd]);

  const handlerChange = ({ target: { name, value } }) => {
    setUserForm({
      ...userForm,
      [name]: { ...userForm[name], value, isTouched: true },
    });
  };

  return (
    <form onSubmit={submitHandler} className="form-add-user">
      <label>
        <input
          type="text"
          name="login"
          value={userForm.login.value}
          placeholder="Логин"
          onChange={handlerChange}
        />
        <div className="error-message">
          {userForm.login.isTouched && errors.login}
        </div>
      </label>
      <label>
        <input
          type="password"
          name="password"
          value={userForm.password.value}
          placeholder="Пароль"
          onChange={handlerChange}
        />
        <div className="error-message">
          {userForm.password.isTouched && errors.password}
        </div>
      </label>
      <button className="btn">Добавить</button>
    </form>
  );
};

export default AddUserForm;
