import React, { useState, useRef } from "react";
import { updateUserPassword } from "../../api/users";

const UserRow = (props) => {
  const { user, handlerDeleteUser, updatesViewUsers } = props;
  const [password, setPassword] = useState({
    value: user.password,
    inputPasswordActive: false,
  });

  const handlerInputActive = () => {
    setPassword({ ...password, inputPasswordActive: true });
  };

  const handlerChangePassword = ({ target: { name, value } }) => {
    setPassword({ ...password, value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setPassword({ ...password, inputPasswordActive: false });

    let props = { id: user.id, password: password.value };
    updateUserPassword(props, updatesViewUsers);
  };

  const handleChangeBlur = () => {
    if (isClosable.current) {
      setPassword({ ...password, inputPasswordActive: false });
    }
  };

  const isClosable = useRef(null);

  const mouseMoveOver = () => {
    isClosable.current = false;
  };
  const mouseMoveOut = () => {
    isClosable.current = true;
  };

  return (
    <tr className="user-item" key={user.id}>
      <td>{user.login}</td>
      <td>
        {!password.inputPasswordActive && (
          <button
            onClick={handlerInputActive}
            className="btn button-change-password"
          >
            {user.password}
          </button>
        )}
        {password.inputPasswordActive && (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="password"
              value={password.value}
              autoFocus={true}
              onChange={handlerChangePassword}
              onBlur={handleChangeBlur}
            />
            <button
              className="btn btn-save-password"
              onMouseOver={mouseMoveOver}
              onMouseOut={mouseMoveOut}
            >
              Сохранить
            </button>
          </form>
        )}
      </td>
      <td>
        <button className="btn" onClick={() => handlerDeleteUser(user.login)}>
          Удалить
        </button>
      </td>
    </tr>
  );
};

export default UserRow;
