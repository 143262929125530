import React, { useState } from "react";

const Search = (props) => {
  const { UpdateSearch } = props;
  const handleChange = ({ target: { name, value } }) => {
    UpdateSearch(value);
  };

  return (
    <header>
      <div className="search">
        <input
          type="text"
          name="search"
          className="search-order-input"
          placeholder="Поиск по имени клиента, номеру заказа, адресу и комментарию"
          onChange={handleChange}
        />
      </div>
      <div className="title title-search">Город: Кыштым</div>
    </header>
  );
};

export default Search;
