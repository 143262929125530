import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Admin from "./Admin/Index";
import Table from "./OrdersTable/Index";
const Root = (props) => {
  const { isAdmin } = props;
  return (
    <Router>
      <Switch>
        {isAdmin === "1" && (
          <Route exact path="/admin" component={Admin}></Route>
        )}
        <Route exact path="/" component={Table}></Route>
      </Switch>
    </Router>
  );
};

export default Root;
