import React, { useState } from "react";
import Root from "./components/Router";
import Authorization from "./components/Authorization/Authorization";

const App = () => {
  const [state, setState] = useState({ authorization: false, isAdmin: 0 });
  const changeAuth = (data) => {
    const { isAdmin } = data;

    if (data) {
      setState({ authorization: true, isAdmin: isAdmin });
    }
  };

  return (
    <div className="container">
      {state.authorization === false && (
        <Authorization changeAuth={changeAuth} />
      )}
      {state.authorization === true && <Root isAdmin={state.isAdmin} />}
    </div>
  );
};

export default App;
