import React from "react";
import Highlighter from "react-highlight-words";

const OrderRow = (props) => {
  const { orderData, searchData } = props;
  const searchWordsArray = searchData.split(" ");
  return (
    <tr>
      <td>
        {orderData.bad_address} →
        <br />
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={searchWordsArray}
          autoEscape={true}
          textToHighlight={orderData.normal_address}
        />{" "}
        {orderData.check_address}
      </td>
      <td>
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={searchWordsArray}
          autoEscape={true}
          textToHighlight={orderData.name}
        />
        <br />
        {orderData.contact}
      </td>
      <td>
        <div>
          Заказ №{" "}
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={searchWordsArray}
            autoEscape={true}
            textToHighlight={orderData.id}
          />{" "}
          от {orderData.date}
        </div>
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={searchWordsArray}
          autoEscape={true}
          textToHighlight={orderData.note}
        />
      </td>
      <td className="order-images">
        {Object.keys(orderData.product_info).map((index_product, value) => {
          const image =
            "data:image/png;base64," +
            orderData.product_info[index_product].image;
          return (
            <div>
              <img
                src={image}
                // style="width: 100px;"
                alt={orderData.product_info[index_product].image}
              />
              <div className="product-size">
                {orderData.product_info[index_product].width} ×{" "}
                {orderData.product_info[index_product].height}
              </div>
              <div className="product-accessories">
                {orderData.product_info[index_product].windowsill && (
                  <span>
                    п: {orderData.product_info[index_product].windowsill}{" "}
                  </span>
                )}
                {orderData.product_info[index_product].reflux && (
                  <span>о: {orderData.product_info[index_product].reflux}</span>
                )}
              </div>
            </div>
          );
        })}
      </td>
    </tr>
  );
};

export default OrderRow;
