import axios from "axios";

const getUsers = (changeStateUsers) => {
  axios
    .post("https://fyberi-steklandia.na4u.ru/users.php", {
      options: "getUsers",
    })
    .then((response) => {
      changeStateUsers(response.data);
    });
};

const addUser = (data, handleAddUser) => {
  let userValue = Object.fromEntries(data);
  axios
    .post("https://fyberi-steklandia.na4u.ru/users.php", {
      options: "addUser",
      userValue: userValue,
    })
    .then((response) => {
      handleAddUser(response.data);
    });
};

const updateUserPassword = (data, updatesViewUsers) => {
  axios
    .post("https://fyberi-steklandia.na4u.ru/users.php", {
      options: "updateUserPassword",
      userValue: data,
    })
    .then((response) => {
      updatesViewUsers(response.data);
    });
};

const deleteUser = (data, updatesViewUsers) => {
  axios
    .post("https://fyberi-steklandia.na4u.ru/users.php", {
      options: "deleteUser",
      userValue: data,
    })
    .then((response) => {
      updatesViewUsers(response.data);
    });
};

export { getUsers, addUser, updateUserPassword, deleteUser };
