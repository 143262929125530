import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getUsers, deleteUser } from "../../api/users";
import AddUserForm from "./AddUsersForm";
import UserRow from "./UserRow";

const Admin = () => {
  const [users, setUsers] = useState({});
  const [userUpdate, setUserUpdate] = useState(true);

  const changeStateUsers = (data) => {
    setUsers(data);
  };

  const updatesViewUsers = (props) => {
    setUserUpdate(props);
  };

  const handleAddUser = (data) => {
    if (data) {
      setUsers((prevState) => [...prevState, data]);
    }
  };

  useEffect(() => {
    if (userUpdate === true) {
      getUsers(changeStateUsers);
      setUserUpdate(false);
    }
  }, [userUpdate]);

  const handlerDeleteUser = (data) => {
    deleteUser(data, updatesViewUsers);
  };

  return (
    <div className="admin-users">
      <table>
        <tbody>
          <tr>
            <th width="20%">Логин</th>
            <th width="30%">Пароль</th>
            <th></th>
          </tr>
          {users &&
            Object.keys(users).map((index, value) => {
              return (
                <UserRow
                  user={users[index]}
                  handlerDeleteUser={handlerDeleteUser}
                  updatesViewUsers={updatesViewUsers}
                  key={index}
                />
              );
            })}
        </tbody>
      </table>
      <AddUserForm
        updatesViewUsers={updatesViewUsers}
        handleAddUser={handleAddUser}
      />
    </div>
  );
};

export default Admin;
