import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";

const Authorization = (props) => {
  const { changeAuth } = props;
  const [state, setState] = useState({ password: "" });

  useEffect(() => {
    if (state.password.length > 0) {
      axios
        .post("https://fyberi-steklandia.na4u.ru/authorization.php", {
          password: state.password,
        })
        .then((response) => {
          const data = response.data;
          if (data) {
            changeAuth(data);
          }
        });
    }
  }, [state.password]);

  const ChangePassword = _.debounce(({ target: { index, value } }) => {
    setState({ password: value });
  }, 500);

  return (
    <div className="wrapper-auth">
      <div className="title">Вход</div>
      <input type="password" className="auth-input" onChange={ChangePassword} />
    </div>
  );
};

export default Authorization;
